import { GetServerSideProps, routeToUrl, useQueryParams } from "@app-routes";
import { INTRO_COMPLETED_COOKIE_NAME } from "@config/global-constants";
import { GetMeDocument, GetMeQuery, GetMeQueryVariables } from "@gql-schema";
import { Intro } from "@main-zone/components/intro";
import { CookieConsent } from "@shared/components/cookie-consent/cookie-consent";
import { Header } from "@shared/components/header/header";
import { Helmet } from "@shared/components/helmet/helmet";
import { initializeApollo } from "@shared/lib/Apollo";
import { Cookie } from "@uxf/core/cookie";
import { useOnMount } from "@uxf/core/hooks/useOnMount";
import { queryParamToString } from "@uxf/router";
import { useRouter } from "next/router";

const INTRO_COMPLETED_COOKIE_TTL = 1000 * 60 * 60 * 24 * 365 * 10;

function Page() {
    const router = useRouter();
    const params = useQueryParams<"main-zone/intro">();
    const redirectTo = queryParamToString(params.redirect);

    useOnMount(() => {
        Cookie.create().set(INTRO_COMPLETED_COOKIE_NAME, "1", INTRO_COMPLETED_COOKIE_TTL);
    });

    const onCompleteIntro = async () => {
        await router.push(redirectTo ?? routeToUrl("main-zone/index"));
    };

    return (
        <>
            <Helmet title="Vítejte" />
            <div className="flex min-h-screen flex-col">
                <Header hideLogoBarOnMobile />
                <main className="container grow">
                    <Intro onComplete={onCompleteIntro} />
                </main>
                <CookieConsent />
            </div>
        </>
    );
}

Page.noHistory = true;

export const getServerSideProps: GetServerSideProps<"main-zone/intro"> = async (ctx) => {
    const apolloClient = initializeApollo(ctx);
    const redirectTo = queryParamToString(ctx.query.redirect);

    const user = await apolloClient.query<GetMeQuery, GetMeQueryVariables>({ query: GetMeDocument });

    if (user.data.getMe.isLogged) {
        Cookie.create(ctx).set(INTRO_COMPLETED_COOKIE_NAME, "1", INTRO_COMPLETED_COOKIE_TTL);

        return {
            redirect: {
                destination: redirectTo ?? routeToUrl("main-zone/index"),
                permanent: false,
            },
        };
    }

    return {
        props: {},
    };
};

export default Page;
